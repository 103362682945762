import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  AxiosCacheInstance,
  CacheRequestConfig,
  CacheUpdaterRecord,
  MemoryStorage,
  buildKeyGenerator,
  setupCache,
} from 'axios-cache-interceptor';
import { configuration } from '../configuration';
import { ApiList } from './api/ApiList';

const apis = [configuration.AUTH0_DOMAIN_URL, ...Object.values(ApiList)];

// Create an instance of Axios
const axiosInstance = axios.create();

// https://axios-cache-interceptor.js.org/guide/getting-started
setupCache(axiosInstance, {
  generateKey: buildKeyGenerator((request) =>
    // Remove the base URL from the cache key
    request.url?.replace(/^https?:\/\/[\w.]+(:[\d]{4})?\//, ''),
  ),
});

// Add a request interceptor for applying Authorization header
axiosInstance.interceptors.request.use(
  async (config) => {
    const { reduxServices } = await import('../reducers/reduxServices');

    // Only add our Authorization header / access token to requests to our own APIs
    const baseUrl = config.baseURL || config.url;

    if (apis.some((base) => base && baseUrl?.includes(base))) {
      const accessToken =
        await reduxServices.globalState.getAccessTokenSilently();

      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

// Add a response interceptor for handling errors
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error: AxiosError) => {
    const { reduxServices } = await import('../reducers/reduxServices');

    const status = error.response?.status;
    const url = error.config?.url;

    // Special handling added for /airwallex/auth-code endpoint to avoid a full-page 'access denied' message
    if (status === 401 && !url?.includes('/airwallex/auth-code')) {
      // Dispatch actions to reset app state
      await reduxServices.resetAppState();
    } else {
      const method = error.config?.method?.toUpperCase();
      const baseURL = window.location.pathname;

      // Set current error in state
      reduxServices.setCurrentError({
        code: error?.code,
        message: error?.message,
        method: method,
        status: status,
      });

      // In general, show modal for non-GET requests,
      // Lookups, or download requests
      if (
        method !== 'GET' ||
        url?.includes('/business-number-lookup') ||
        url?.includes('/download') ||
        url?.includes('/invitation/check-existing') ||
        url?.includes(configuration.DOCUMENTS_S3_BUCKET)
      ) {
        let showErrorModal = true;

        // Capability to ignore errors for specific path/status
        if (
          // 400 responses are handed by the forms
          (url?.includes('/business-number-lookup') && status === 400) ||
          (url?.includes('/invitation') && status === 400) ||
          (url?.includes('/payment-rule') && status === 400)
        ) {
          showErrorModal = false;
        }

        reduxServices.showErrorModal(showErrorModal);
      } else {
        let showErrorPage = true;

        // Capability to ignore errors for specific path/status
        if (url?.includes('/business-number-lookup')) {
          showErrorPage = false;
        }

        // Capability to ignore errors for specific path/status
        if (
          // 404 is expected for users/me at first login
          url?.includes('/user/me') &&
          status === 404
        ) {
          showErrorPage = false;
        }

        // Ignore cannot find client in title summary and staff title summary
        if (
          url?.includes('/client') &&
          status === 404 &&
          (baseURL?.includes('/staff/workspaces/manage-workspaces') ||
            baseURL?.includes('/titles/summary'))
        ) {
          showErrorPage = false;
        }
        reduxServices.showErrorPage(showErrorPage);
      }

      throw error;
    }
  },
);

export const axiosCacheInstance = axiosInstance as AxiosCacheInstance;

export const axiosCacheStorage = axiosCacheInstance.storage as MemoryStorage;

export const invalidateCacheContaining = async (partialKey: string) => {
  const matchingKeys = Object.keys(axiosCacheStorage.data).filter((key) =>
    key.includes(partialKey),
  );

  const promises = [];

  for (const matchingKey of matchingKeys) {
    promises.push(axiosCacheStorage.remove(matchingKey));
  }

  await Promise.all(promises);
};

export const invalidateCacheRequestConfig = (...partialKey: string[]) => {
  const matchingKeys = Object.keys(axiosCacheStorage.data).filter((cachedKey) =>
    partialKey.some((key) => cachedKey.includes(key)),
  );

  const cacheUpdaterValue = matchingKeys.reduce(
    (map: CacheUpdaterRecord<any, any>, key) => {
      map[key] = 'delete';

      return map;
    },
    {},
  );

  const config: CacheRequestConfig = {
    cache: {
      update: cacheUpdaterValue,
    },
  };

  return config;
};

export default axiosInstance;
