import { useAuth0 } from '@auth0/auth0-react';
import { SonnerToaster } from '@screenrights/ui-library';
import * as Sentry from '@sentry/react';
import React, { Suspense, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { AppLoader } from './components/Loader';
import { userService } from './reducers/permissions/userSlice';
import { reduxServices } from './reducers/reduxServices';
import { AppRouter } from './routes';
import { useAppDispatch, useTypedSelector } from './stateStore';

const App = () => {
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const currentUser = useTypedSelector(userService.selectors.userMe);

  useEffect(() => {
    const fetchUserMe = async () => {
      reduxServices.globalState.getAccessTokenSilently = getAccessTokenSilently;
      dispatch(userService.findMe());
      dispatch(reduxServices.setting.findAllFeatures());
    };

    if (isAuthenticated) fetchUserMe();
  }, [dispatch, getAccessTokenSilently, isAuthenticated]);

  useEffect(() => {
    if (!currentUser) return;

    // Ensure the current user is included with Sentry error reports
    Sentry.configureScope((scope) => {
      scope.setUser({
        id: currentUser.id,
      });
    });
  }, [currentUser]);

  if (isLoading)
    return <AppLoader.FlexLoader loaderHeight="h-screen" size="2x" />;

  return (
    <Suspense
      fallback={<AppLoader.FlexLoader className="h-screen" size="2x" />}
    >
      <RouterProvider router={AppRouter} />
      <SonnerToaster duration={7000} position="top-center" />
    </Suspense>
  );
};

export default App;
